<template>
  <div class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="user">
      <b-row>
        <b-col lg="12" md="12">
          <b-form-group>
            <b-form>
              <legend>
                <strong>Person</strong>
              </legend>

              <b-form-group
                horizontal
                :label="$t('salutation')"
                label-for="salutation"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <multiselect
                  v-model="user.gender"
                  id="salutation"
                  :options="getGenders"
                  :placeholder="$t('select_salutation')"
                  :allow-empty="false"
                  :selectLabel="$t('press_enter_to_select')"
                  :selectedLabel="$t('selected')"
                  deselectLabel
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
                  <template slot="option" slot-scope="{ option }">{{ $t(option) }}</template>
                </multiselect>
              </b-form-group>

              <b-form-group
                horizontal
                label="Vorname"
                label-for="firstname"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input
                  v-model="user.firstname"
                  type="text"
                  id="firstname"
                  placeholder="Vorname"
                />
              </b-form-group>
              <b-form-group
                horizontal
                label="Nachname"
                label-for="lastname"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input
                  v-model="user.lastname"
                  type="text"
                  id="lastname"
                  placeholder="Nachname"
                />
              </b-form-group>

              <legend>
                <strong>{{ $t('company') }}</strong>
              </legend>

              <b-form-group
                horizontal
                :label="$t('clients') + ' (Neu)'"
                label-for="clients"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <multiselect
                  class="multiselect-clients"
                  v-model="user.clients"
                  :options="filteredClients"
                  track-by="number"
                  v-on:search-change="onFilterClients"
                  v-on:select="onSelectClient(user)"
                  v-on:remove="onSelectClient(user)"
                  :filter="true"
                  :internal-search="false"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :multiple="true"
                  :show-labels="false"
                  :allow-empty="true"
                  :deselectLabel="$t('deselectLabel')"
                  selectLabel=""
                  selectedLabel=""
                  :placeholder="$t('client') + ' ' + $t('select') + '/suchen'"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }}
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    {{ option.name }}
                  </template>
                  <template slot="tag" slot-scope="{ option }">
                    <div>{{ option.name }}</div>
                  </template>
                  <template slot="noResult">
                    <div>{{ $t('no_results') }}</div>
                  </template>
                </multiselect>
              </b-form-group>

              <b-form-group
                horizontal
                :label="$t('client')"
                label-for="client"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <multiselect
                  :placeholder="$t('select')"
                  label="name"
                  track-by="name"
                  v-model="user.client"
                  id="client"
                  :options="getClients"
                  :allow-empty="true"
                  :selectLabel="$t('press_enter_to_select')"
                  :selectedLabel="$t('selected')"
                  :deselectLabel="$t('deselectLabel')"
                ></multiselect>
              </b-form-group>

              <b-form-group
                horizontal
                label="Position"
                label-for="position"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input
                  v-model="user.position"
                  type="text"
                  id="position"
                  placeholder="Position"
                />
              </b-form-group>

              <legend>
                <strong>Zugang</strong>
              </legend>
              <b-form-group
                horizontal
                label="Berechtigung"
                label-for="role"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <multiselect
                  placeholder="Berechtigung wählen"
                  v-model="user.role"
                  id="role"
                  :options="userRoles"
                  :allow-empty="false"
                  :selectLabel="$t('press_enter_to_select')"
                  :selectedLabel="$t('selected')"
                  deselectLabel
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
                  <template slot="option" slot-scope="{ option }">{{ $t(option) }}</template>
                </multiselect>
              </b-form-group>
              <b-form-group
                horizontal
                label="E-Mail"
                label-for="email"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-input-group id="email">
                  <b-input-group-text slot="prepend">
                    <i class="fa fa-envelope" />
                  </b-input-group-text>
                  <b-form-input v-model="user.email" type="email" />
                </b-input-group>
              </b-form-group>

              <b-form-group
                horizontal
                label="Passwort"
                label-for="password"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-input-group id="password">
                  <b-input-group-text slot="prepend">
                    <i class="fa fa-lock" />
                  </b-input-group-text>
                  <b-input-group-text slot="append">
                    <button class="btn btn-sm p-0" @click.prevent="togglePassword">
                      <i class="fa fa-eye" />
                    </button>
                  </b-input-group-text>
                  <b-input-group-text slot="append" class="btn-icon">
                    <button class="btn btn-sm p-0" @click.prevent="generatePassword">
                      <i class="fa fa-repeat mr-2" />
                      Passwort generieren
                    </button>
                  </b-input-group-text>
                  <b-form-input
                    v-model="user.password"
                    :type="passwordType"
                    id="password"
                    autocomplete="new-password"
                  />
                </b-input-group>
              </b-form-group>
            </b-form>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="user"
            modelEditRoute="UserEditPage"
            modelListRoute="UsersPage"
            modelRouteParamName="userNumber"
            :updateCallback="updateUser"
            :createCallback="createUser"
            :deleteCallback="deleteUserByNumber"
            :fetchCallback="fetchUsers"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import Multiselect from 'vue-multiselect';
import { mapGetters, mapActions } from 'vuex';
import passwordGenerator from 'generate-password';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  components: {
    Widget,
    Breadcrumbs,
    Multiselect,
    ButtonBar,
  },
  props: {
    userNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      buttonsDisabled: false,
      passwordType: 'password',
      filteredClients: [],
    };
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUsers',
      'getUserRoles',
      'getGenders',
      'getEnumValues',
      'getClients',
      'getUserByNumber',
    ]),
    user() {
      return this.getUserByNumber(this.number);
    },
    buttons() {
      return {
        showDeleteButton: this.user && this.user.number !== '_new' ? true : false,
      };
    },
    isSendEmailButtonDisabled() {
      return (
        !this.user.password ||
        this.user.password === '' ||
        this.user.password === null ||
        !this.user.email ||
        this.user.email === ''
      );
    },
    companies() {
      return this.getCompanies;
    },
    userRoles() {
      const userRoles = [];
      this.getUserRoles.map((role) => {
        userRoles.push(role.name);
      });

      return userRoles;
    },
    avatarImage() {
      if (this.user.avatar) {
        return this.user.avatar.path;
      }
      return null;
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: 'Benutzer', route: { name: 'UsersPage' } },
        { name: this.user ? this.user.firstname + ' ' + this.user.lastname : 'neu' },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initUser',
      'fetchUsers',
      'fetchUserRoles',
      'updateUser',
      'createUser',
      'deleteUser',
      'deleteUserByNumber',
      'fetchGenders',
      'messageCredentialsToUser',
      'fetchEnumValues',
      'fetchClients',
      'fetchUserByNumber',
    ]),
    async onSendCredentialsEmailToUser() {
      await this.messageCredentialsToUser(this.user);
      await this.saveUser();
    },
    togglePassword() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
    async loadData() {
      if (this.number && this.number !== '_new') {
        await this.fetchUserByNumber(this.number);
      } else {
        this.initUser();
        this.number = '_new';
        this.generatePassword();
      }
      await this.fetchUserRoles();
      await this.fetchGenders();
      await this.fetchClients();
      this.filteredClients = this.getClients;
    },
    generatePassword() {
      this.$set(
        this.user,
        'password',
        passwordGenerator.generate({
          length: 12,
          numbers: true,
        })
      );
    },
    userRoleColor($role) {
      if ($role === 'ADMIN') return 'danger';
      if ($role === 'EDITOR') return 'warning';
      if ($role === 'CUSTOMER') return 'success';
    },

    /**
     * Search for clients in multiselect
     */
    async onFilterClients(query) {
      this.filteredClients = [];
      this.getClients.map((client) => {
        if (client.name && client.name.toLowerCase().indexOf(query) >= 0) {
          this.filteredClients.push(client);
        }
      });
    },

    /**
     * Select clients in multiselect
     * @param {*} row
     */
    async onSelectClient(row) {
      console.log('onSelectClient', row);
      // const { data } = row;
      // await this.save(data);
    },
  },
  async mounted() {
    if (this.userNumber) {
      this.number = this.userNumber;
    } else if (this.$route.params.userNumber) {
      this.number = this.$route.params.userNumber;
    }
    this.loadData();
  },
};
</script>

<style scoped>
.avatar-preview {
  width: 150px;
  position: relative;
}
#image-delete-button {
  position: absolute;
  top: 0;
}
.btn-icon {
  cursor: pointer;
}
</style>
